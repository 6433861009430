<template>
  <Layout>
    <form @submit.prevent="submit">
      <div class="section-content max-w-md mx-auto">
        <div class="form">
          <div class="form-heading text-center mb-6">
            <h5 class="mb-1">Recuperação de senha</h5>
            <p class="text-sm">
              Informe seu e-mail e clique em recuperar senha, você receberá um
              e-mail com instruções para fazer uma nova senha.
            </p>
          </div>

          <div class="body">
            <div
              :class="{ 'group-horizontal': true, groupError: $v.email.$error }"
            >
              <label class="label"> E-mail </label>

              <input
                type="text"
                placeholder="Informe seu e-mail"
                v-model.trim="$v.email.$model"
              />
            </div>
            <div :class="{ 'text-right': true, groupError: $v.email.$error }">
              <p class="messageError" v-if="!$v.email.required">
                E-mail deve ser preenchido.
              </p>

              <p class="messageError" v-if="!$v.email.email">
                E-mail informado deve ser válido.
              </p>
            </div>
          </div>
        </div>

        <div class="form-footer">
          <Button type="submit" color="white" background="lime-600">
            Recuperar senha
          </Button>
        </div>
      </div>
    </form>
  </Layout>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import Layout from "@/components/layout/subscription/Layout.vue";
import Button from "@/components/elements/Button.vue";
import services from "@/services";

export default Vue.extend({
  name: "SubscriptionRecovery",
  data() {
    return {
      email: "",
    };
  },
  components: {
    Layout,
    Button,
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    ...mapActions(["changeLoading", "sendNotification"]),
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.changeLoading(true);

        await services.Subscription.sendRecovery({ email: this.email }).then(
          (response) => {
            this.changeLoading(false);

            this.$router.push({ name: "SubscriptionLogin" });
            this.sendNotification({
              type: "success",
              message: response.data.message,
            });
          }
        );
      }
    },
  },
});
</script>
